$(function() {
  var lastChecked = null;
  var $checkboxes = $('.list-checkbox');

  $checkboxes.click(function(e) {
    if (!lastChecked) {
      lastChecked = this;
      return;
    }

    if (e.shiftKey) {
      var start = $checkboxes.index(this);
      var end = $checkboxes.index(lastChecked);

      $checkboxes.slice(Math.min(start, end), Math.max(start, end)).prop('checked', lastChecked.checked);
    }

    lastChecked = this;
  });
});
