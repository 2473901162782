import { Controller } from "@hotwired/stimulus"
import sortable from 'html5sortable/dist/html5sortable.es';
import { put } from '@rails/request.js';

export default class extends Controller {
  static values = {
    url: String,
    id: Array
  };

  connect() {
    sortable(this.element);
    this.element.addEventListener('sortupdate', (evt) => this.saveOrder(evt));
  }

  saveOrder(evt) {
    var ids = [];
    for (var i = 0; i < evt.detail.destination.items.length; i += 1) {
      let item = evt.detail.destination.items[i];
      ids.push(parseInt(item.getAttribute('data-id'), 10));
    }

    put(this.urlValue, {
      body: JSON.stringify({ids: ids})
    });
  }
}
