import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['file', 'uploadForm', 'parseResults'];

  connect() {
    this.clearResults();
  }

  async findUsers() {
    this.clearResults();
    this.displayLoading();
    let response = await this.searchUsersRequest();
    this.hideLoading();

    if (!response.ok) {
      this.displayError('No users found'); // TODO Show actual error
      return;
    }

    this.users = await response.json;
    this.displayUsers();
  }

  addUsersToBlanketRequest(users) {
    let template = document.getElementById('items_fields_blueprint').getAttribute('data-blueprint');
    let regexp = new RegExp('new_items', 'g');

    for (var i = 0; i < users.length; i += 1) {
      let id = this.newId;
      let content = template.replace(regexp, id);
      document.getElementById('items').innerHTML += content;

      let user = users[i];
      let option = document.createElement('option');
      option.setAttribute('selected', true);
      option.value = user.id;
      option.innerHTML = `${user.first_name} ${user.last_name}`;

      let element = document.getElementById(`blanket_request_items_attributes_${id}_user_id`);
      element.appendChild(option);
    }

    window.bindNestedForms();
    $('#bulk_add_travellers').modal('hide');
    this.clearResults();
    this.users = null;
  }

  get newId() {
    return new Date().getTime()
  }

  async searchUsersRequest() {
    console.log(this.fileTarget.files[0]);
    var data = new FormData();
    data.append('csv_file', this.fileTarget.files[0]);

    return await post("/api/v1/users/search", {
      responseKind: "json",
      body: data
    });
  }

  displayUsers() {
    if (!this.users || this.users.length == 0) {
      this.displayError('No users found');
      return;
    }

    let message = this.parseResultsTarget.getAttribute('data-success-message');
    this.parseResultsTarget.innerHTML += `<div class="alert alert-info">${message}</div>`;
    this.parseResultsTarget.appendChild(this.usersTable());
    this.parseResultsTarget.appendChild(this.addButton());
  }

  usersTable() {
    let table = document.createElement('table');
    table.classList.add('dashboard-table');
    for (var i = 0; i < this.users.length; i += 1) {
      let result = this.users[i];
      table.innerHTML += `<tr><td>${result.email}</td><td>${result.first_name} ${result.last_name}</td></tr>`;
    }
    return table;
  }

  addButton() {
    let button = document.createElement('button');
    button.classList.add('btn');
    button.classList.add('btn-primary');
    button.appendChild(document.createTextNode('Add Users'));
    button.onclick = () => this.addUsersToBlanketRequest(this.users);
    return button;
  }

  clearResults() {
    this.parseResultsTarget.innerHTML = '';
  }

  displayLoading() {
    this.loading = document.createElement('div');
    this.loading.classList.add('loading');
    this.parseResultsTarget.appendChild(this.loading);
  }

  hideLoading() {
    if (this.loading) {
      this.loading.remove();
      this.loading = null;
    }
  }

  hideUploadForm() {
    this.uploadFormTarget.classList.add('hide');
  }

  showUploadForm() {
    this.uploadFormTarget.classList.remove('hide');
  }

  displayError(message) {
    this.parseResultsTarget.innerHTML = `<div class="alert alert-warning">${message}</div>`;
  }
}
