$('body').on('change', '#include_children', function() {
  if ($(this).prop('checked')) {
    $('#report_include_children').val('1');
  } else {
    $('#report_include_children').val('0');
  }
  $('#new_report').submit();
});

import WeightInput from './components/weightInput';
import HeightInput from './components/heightInput';

class ComponentHandler {
  constructor() {
    /**
     * Performs a "Cutting the mustard" test. If the browser supports the features
     * tested, adds a js-tst class to the <html> element.
     */
    if (
      'classList' in document.createElement('div') &&
      'querySelector' in document &&
      'addEventListener' in window &&
      Array.prototype.forEach
    ) {
      document.documentElement.classList.add('js-tst');
    }

    this.bootstrapAllComponents();
  }

  bootstrapAllComponents() {
    WeightInput.bootstrapComponents();
    HeightInput.bootstrapComponents();
  }
}

const handler = new ComponentHandler();
$(document).on('ajaxComplete', function() {
  handler.bootstrapAllComponents();
});

export default handler;
