import Component from '../component';
import { classes, attributes } from './weightInput.const';

export default class WeightInput extends Component {
  static bootstrapComponents() {
    const components = [];

    Array.prototype.forEach.call(document.querySelectorAll(`.${classes.COMPONENT}`), element => {
      if (!Component.isElementBootstrapped(element)) {
        components.push(new WeightInput(element));
      }
    });

    return components;
  }

  init() {
    super.init();

    this.inputElements = this.fdsElement.querySelectorAll(`.${classes.INPUT}`);

    if (this.inputElements.length > 0) {
      this.inputElements.forEach(inputElement => {
        inputElement.setAttribute('type', 'hidden');
        this.setMode(inputElement, 'metric');
      });
    }
  }

  addonInput(label_text, input_class) {
    const input = document.createElement('input');
    input.setAttribute(attributes.TYPE, 'number');
    input.classList.add(input_class);
    input.classList.add('form-control');

    const label = document.createElement('span');
    label.classList.add('input-group-addon');
    label.appendChild(document.createTextNode(label_text));

    const wrapper = document.createElement('div');
    wrapper.classList.add('input-group');
    wrapper.appendChild(input);
    wrapper.appendChild(label);

    return wrapper;
  }

  metricInput() {
    const kgInput = this.addonInput('kg', classes.KG_INPUT);
    const wrapper = document.createElement('div');

    wrapper.appendChild(kgInput);
    wrapper.classList.add(classes.METRIC);

    return wrapper;
  }

  imperialInputs() {
    const stoneInput = this.addonInput('Stone', classes.STONE_INPUT);
    const lbInput = this.addonInput('Pounds', classes.LB_INPUT);
    const wrapper = document.createElement('div');

    wrapper.classList.add(classes.IMPERIAL);
    wrapper.appendChild(stoneInput);
    wrapper.appendChild(lbInput);

    return wrapper;
  }

  number(value) {
    const result = parseFloat(value);
    if (isNaN(result)) {
      return 0;
    }

    return result;
  }

  calculateKg(stone, pounds) {
    const lb = (this.number(stone) * 14) + this.number(pounds);
    const result = (lb * 0.4535924).toFixed(2);
    console.log(stone, pounds, lb, result);
    return result;
  }

  calculateStone(kg) {
    const lb = this.number(kg) / 0.45;
    return Math.floor(lb / 14);
  }

  calculateLb(kg) {
    const lb = this.number(kg) / 0.45;
    return Math.round(lb % 14);
  }

  showMetric(element, focus) {
    const imperialInputs = element.parentElement.querySelector(`.${classes.IMPERIAL}`);
    if (imperialInputs != null) {
      imperialInputs.remove();
    }
    const inputs = this.metricInput();
    element.parentElement.insertBefore(inputs, element);

    const switcher = this.switchButton('Switch to st, lb');
    switcher.addEventListener('click', (event) => {
      event.preventDefault();
      this.setMode(element, 'imperial', true)
    });
    inputs.appendChild(switcher);

    const kgInput = element.parentElement.querySelector(`.${classes.KG_INPUT}`);
    kgInput.value = element.value;
    kgInput.addEventListener('input', function(evt) {
      element.value = evt.target.value;
    });
    if (focus) {
      kgInput.focus();
      kgInput.select();
    }
  }

  showImperial(element, focus) {
    const metricInputs = element.parentElement.querySelector(`.${classes.METRIC}`);
    if (metricInputs != null) {
      metricInputs.remove();
    }
    const inputs = this.imperialInputs();
    element.parentElement.insertBefore(inputs, element);

    const switcher = this.switchButton('Switch to kg');
    switcher.addEventListener('click', (event) => {
      event.preventDefault();
      this.setMode(element, 'metric', true)
    });
    inputs.appendChild(switcher);

    const stoneInput = element.parentElement.querySelector(`.${classes.STONE_INPUT}`);
    stoneInput.value = this.calculateStone(element.value);
    if (focus) {
      stoneInput.focus();
      stoneInput.select();
    }

    const lbInput = element.parentElement.querySelector(`.${classes.LB_INPUT}`);
    lbInput.value = this.calculateLb(element.value);

    stoneInput.addEventListener('input', (evt) => {
      element.value = this.calculateKg(evt.target.value, lbInput.value);
    })

    lbInput.addEventListener('input', (evt) => {
      element.value = this.calculateKg(stoneInput.value, evt.target.value);
    })
  }

  setMode(element, mode, focus) {
    if (mode == 'metric') {
      this.showMetric(element, focus);
    } else {
      this.showImperial(element, focus);
    }
  }

  switchButton(title) {
    const switcher = document.createElement('a');
    switcher.setAttribute('href', '#');
    switcher.setAttribute('type', 'button');
    switcher.setAttribute('tabindex', '-1');
    switcher.innerText = title;
    return switcher;
  }
}
