export const classes = {
  COMPONENT: 'js-tst-height-field',
  INPUT: 'tst-height-field__input',
  SWITCHER: 'tst-height-field__switcher',
  SWITCHER_BUTTON: 'tst-height-field__switcher-btn',
  FEET_INPUT: 'tst-height-field__feet',
  INCHES_INPUT: 'tst-height-field__inches',
  IMPERIAL: 'tst-height-field__imperial',
  METRIC: 'tst-height-field__metric',
  METRES_INPUT: 'tst-height-field__metres'
};

export const attributes = {
  TYPE: 'type'
};
