export default {
  "name": "Tracker",
  "uri": "http://gtglobaltracker.com/schema/bpmn/tracker",
  "prefix": "tracker",
  "xml": {
    "tagAlias": "lowerCase"
  },
  "types": [
    {
      "name": "ScriptMessage",
      "superClass": [ "Element" ],
      "properties": [
        {
          "name": "body",
          "isBody": true,
          "type": "String"
        }
      ]
    }
  ],
  "emumerations": [],
  "associations": []
}
