import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "activity",
    "activityToggle"
  ];

  static values = {
    status: Array
  };

  connect() {
    this.openActivity(this.activityToggleTargets[0]);
  }

  openActivity(toggle) {
    toggle.click();
  }
}
