import Rails from '@rails/ujs';

$(function() {
  document.body.addEventListener("ajax:before", (event) => {
    if (event.target.id !== 'add_visa') {
      return true;
    }

    var trip_country = $('#trip_location,#pre_trip_location').val().split(':')[2];
    if (!trip_country) {
      alert('Please select a city first');
      event.preventDefault();
      return false;
    }

    $('#add_visa').attr('data-params', 'country_code='+trip_country);
    return true;
  });

  $('#new_visa_modal').on('submit', 'form', function(ev) {
    ev.preventDefault();

    Rails.ajax({
      type: "POST",
      url: "/visas.js",
      data: new FormData($(this)[0])
    });
  })
});
