import Component from '../component';
import { classes, attributes } from './heightInput.const';

export default class HeightInput extends Component {
  static bootstrapComponents() {
    const components = [];

    Array.prototype.forEach.call(document.querySelectorAll(`.${classes.COMPONENT}`), element => {
      if (!Component.isElementBootstrapped(element)) {
        components.push(new HeightInput(element));
      }
    });

    return components;
  }

  init() {
    super.init();

    this.inputElements = this.fdsElement.querySelectorAll(`.${classes.INPUT}`);

    if (this.inputElements.length > 0) {
      this.inputElements.forEach(inputElement => {
        inputElement.setAttribute('type', 'hidden');
        this.setMode(inputElement, 'metric', false);
      });
    }
  }

  addonInput(label_text, input_class) {
    const input = document.createElement('input');
    input.setAttribute(attributes.TYPE, 'number');
    input.classList.add(input_class);
    input.classList.add('form-control');

    const label = document.createElement('span');
    label.classList.add('input-group-addon');
    label.appendChild(document.createTextNode(label_text));

    const wrapper = document.createElement('div');
    wrapper.classList.add('input-group');
    wrapper.appendChild(input);
    wrapper.appendChild(label);

    return wrapper;
  }

  metricInput() {
    const input = this.addonInput('m', classes.METRES_INPUT);
    const wrapper = document.createElement('div');

    wrapper.classList.add(classes.METRIC);
    wrapper.appendChild(input);

    return wrapper;
  }

  imperialInputs() {
    const ftInput = this.addonInput('Feet', classes.FEET_INPUT);
    const inchesInput = this.addonInput('Inches', classes.INCHES_INPUT);
    const wrapper = document.createElement('div');

    wrapper.classList.add(classes.IMPERIAL);
    wrapper.appendChild(ftInput);
    wrapper.appendChild(inchesInput);

    return wrapper;
  }

  switchButton(title) {
    const switcher = document.createElement('a');
    switcher.setAttribute('href', '#');
    switcher.setAttribute('type', 'button');
    switcher.setAttribute('tabindex', '-1');
    switcher.innerText = title;
    return switcher;
  }

  number(value) {
    const result = parseFloat(value);
    if (isNaN(result)) {
      return 0;
    }

    return result;
  }

  calculateMetres(ft, inches) {
    const total_inches = (this.number(ft) * 12) + this.number(inches);
    const result = (total_inches * 2.54).toFixed(2);
    return (result / 100.0).toFixed(2);
  }

  calculateFeet(metres) {
    const inches = (this.number(metres) * 100) / 2.54;
    return Math.floor(inches / 12);
  }

  calculateInches(metres) {
    const total_inches = (this.number(metres) * 100) / 2.54;
    return Math.round(total_inches % 12);
  }

  showMetric(element, focus) {
    const imperialInputs = element.parentElement.querySelector(`.${classes.IMPERIAL}`);
    if (imperialInputs != null) {
      imperialInputs.remove();
    }
    const inputs = this.metricInput();
    element.parentElement.insertBefore(inputs, element);

    const switcher = this.switchButton('Switch to ft, in');
    switcher.addEventListener('click', (event) => {
      event.preventDefault();
      this.setMode(element, 'imperial', true)
    });
    inputs.appendChild(switcher);

    const input = element.parentElement.querySelector(`.${classes.METRES_INPUT}`);
    input.value = element.value;
    input.addEventListener('input', function(evt) {
      element.value = evt.target.value;
    });
    if (focus) {
      input.focus();
      input.select();
    }
  }

  showImperial(element, focus) {
    const metricInputs = element.parentElement.querySelector(`.${classes.METRIC}`);
    if (metricInputs != null) {
      metricInputs.remove();
    }
    const inputs = this.imperialInputs();
    element.parentElement.insertBefore(inputs, element);

    const switcher = this.switchButton('Switch to m');
    switcher.addEventListener('click', (event) => {
      event.preventDefault();
      this.setMode(element, 'metric', true)
    });
    inputs.appendChild(switcher);

    const feetInput = element.parentElement.querySelector(`.${classes.FEET_INPUT}`);
    feetInput.value = this.calculateFeet(element.value);
    if (focus) {
      feetInput.focus();
      feetInput.select();
    }

    const inchesInput = element.parentElement.querySelector(`.${classes.INCHES_INPUT}`);
    inchesInput.value = this.calculateInches(element.value);

    feetInput.addEventListener('input', (evt) => {
      element.value = this.calculateMetres(evt.target.value, inchesInput.value);
    })

    inchesInput.addEventListener('input', (evt) => {
      element.value = this.calculateMetres(feetInput.value, evt.target.value);
    })
  }

  setMode(element, mode, focus) {
    if (mode == 'metric') {
      this.showMetric(element, focus);
    } else {
      this.showImperial(element, focus);
    }
  }
}
