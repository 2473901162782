import { Controller } from "@hotwired/stimulus"
import BpmnModeler from 'bpmn-js/lib/Modeler';
import TrackerPaletteProviderModule from '../src/workflows/features/palette';
import TrackerPopupMenuProviderModule from '../src/workflows/features/popup-menu';
import { post } from '@rails/request.js';

import trackerExtension from '../src/workflows/extension';

export default class extends Controller {
  static values = {
    errors: String
  }

  static targets = [
    "modeler",
    "bpmn",
    "propertyPanel",
    "property"
  ];

  connect() {
    this.modeler = new BpmnModeler({
      container: this.modelerTarget,
      keyboard: {
        bindTo: window
      },
      additionalModules: [
        TrackerPaletteProviderModule,
        TrackerPopupMenuProviderModule
      ],
      moddleExtensions: {
        tracker: trackerExtension
      }
    });

    this.currentElement = null;
    this.propertiesUpdated = false;
    this.modeler.on('selection.changed', (event) => this.selectionChanged(event));
    this.loadBPMN(JSON.parse(this.errorsValue));
  }

  clearErrors() {
    if (!this.errorIds) {
      return;
    }

    const overlays = this.modeler.get('overlays');

    for (var i = 0; i < this.errorIds.length; i += 1) {
      overlays.remove(this.errorIds[i]);
    }
  }

  renderErrors(errors) {
    if (!errors) {
      return;
    }

    const overlays = this.modeler.get('overlays');
    const elementRegistry = this.modeler.get('elementRegistry');

    this.errorIds = [];

    for (var elementId in errors) {
      var element = elementRegistry.get(elementId);
      if (!element) {
        continue;
      }
      console.log(element);

      var id = overlays.add(element, {
        position: {
          top: 5,
          left: 5
        },
        html: this.validationError(errors[elementId])
      });

      this.errorIds.push(id);
    }
  }

  validationError(message) {
    let container = document.createElement('div')
    container.classList.add('workflow-validation-error');

    let icon = document.createElement('span')
    icon.classList.add('fas');
    icon.classList.add('fa-exclamation-triangle');

    let messageContainer = document.createElement('div');
    messageContainer.classList.add('workflow-validation-message');
    messageContainer.innerHTML = message;

    container.appendChild(icon);
    container.appendChild(messageContainer);
    return container;
  }

  selectionChanged(event) {
    this.currentElement = null;
    this.hideAllPanels();

    if (!this.showPropertiesForSelectionEvent(event)) {
      return;
    }

    this.currentElement = event.newSelection[0];
    this.propertiesUpdated = false;
    let panel = this.panelForElement(this.currentElement);

    if (!panel) {
      return;
    }

    this.showPanel(panel, this.currentElement);
  }

  panelForElement(element) {
    var searchType = element.type;
    if (element.type == 'bpmn:IntermediateCatchEvent') {
      let eventDefinition = this.getEventDefinition(element);
      searchType = eventDefinition.$type;
    }

    console.log(element, searchType);

    return this.propertyPanelTargets.find((target) => {
      return target.getAttribute('data-bpmn-type') == searchType;
    });
  }

  async validate() {
    this.clearErrors();

    let { xml, error } = await this.modeler.saveXML();
    if (error) {
      return;
    }

    let response = await post('/admin/workflow_templates/validate_bpmn', {
      contentType: 'application/json',
      responseKind: 'json',
      body: { bpmn: xml }
    })

    let errors = await response.json;
    this.renderErrors(errors);
  }

  hideAllPanels() {
    this.propertyPanelTargets.forEach((target) => {
      target.classList.add('hide');
    });
  }

  showPanel(panel, element) {
    panel.classList.remove('hide');
    let inputs = panel.querySelectorAll('[data-action]');
    Array.prototype.forEach.call(inputs, input => {
      this.populatePropertyInput(input, element);
    });
  }

  showPropertiesForSelectionEvent(event) {
    if (event.newSelection.length != 1) {
      return false;
    }

    let element = event.newSelection[0];
    if (element.type == 'bpmn:SequenceFlow' && !this.elementNeedsSequenceFlowCondition(element)) {
      return false;
    }

    return true;
  }

  populatePropertyInput(input, element) {
    if (this.isElementType(element, 'bpmn:IntermediateCatchEvent')) {
      const eventDefinition = this.getEventDefinition(element);
      const timeDuration = eventDefinition.get('timeDuration');
      if (timeDuration) {
        input.value = timeDuration.body;
        return;
      }
    }

    if (input.getAttribute('data-property-type') == 'condition') {
      input.value = this.getConditionExpression(element);
    } else if (input.getAttribute('data-property-type') == 'script_message') {
      input.value = this.getScriptMessage(element);
    } else {
      input.value = element.businessObject.get(input.getAttribute('name')) || '';
    }
  }

  getConditionExpression(element) {
    var conditionExpression = element.businessObject.conditionExpression;
    if (conditionExpression) {
      return conditionExpression.body;
    }
    return '';
  }

  getScriptMessage(element) {
    var extensionElement = this.getExtensionElement(element.businessObject, 'tracker:ScriptMessage');
    if (extensionElement) {
      return extensionElement.body;
    }
    return '';
  }

  getEventDefinition(element) {
    let eventDefinitions = element.businessObject.get('eventDefinitions') || [];
    return eventDefinitions[0];
  }

  getExtensionElement(element, type) {
    if (!element.extensionElements) {
      return;
    }

    return element.extensionElements.values.filter((extensionElement) => {
      return extensionElement.$instanceOf(type);
    })[0];
  }

  updateProperty(event) {
    if (!this.currentElement) {
      return;
    }

    this.propertiesUpdated = true;

    const moddle = this.modeler.get('moddle');
    const modeling = this.modeler.get('modeling');

    let bpmnAttribute = event.target.getAttribute('name');
    var updates = {};
    if (event.target.getAttribute('data-property-type') == 'condition') {
      updates.conditionExpression = moddle.create('bpmn:FormalExpression', {
        body: event.target.value
      });
    } else if (event.target.getAttribute('data-property-type') == 'script_message') {
      var extensionElements = moddle.create('bpmn:ExtensionElements');
      var scriptMessage = moddle.create('tracker:ScriptMessage', {
        body: event.target.value
      });
      extensionElements.get('values').push(scriptMessage);
      updates.extensionElements = extensionElements;
    } else {
      updates[bpmnAttribute] = event.target.value;
    }
    modeling.updateProperties(this.currentElement, updates);

    if (this.propertiesUpdated) {
      this.validate();
    }
  }

  isElementType(element, type) {
    let bo = element.businessObject;
    return bo && (typeof bo.$instanceOf === 'function') && bo.$instanceOf(type);
  }

  updateTimerDefinition(event) {
    if (!this.currentElement) {
      return;
    }

    const timerEventDefinition = this.getEventDefinition(this.currentElement);
    const modeling = this.modeler.get('modeling');
    const moddle = this.modeler.get('moddle');

    var formalExpression = timerEventDefinition.get('timeDuration');
    if (!formalExpression) {
      formalExpression = moddle.create('bpmn:FormalExpression', { body: undefined });
      formalExpression.$parent = timerEventDefinition;
      modeling.updateModdleProperties(this.currentElement, timerEventDefinition, {
        timeDuration: formalExpression
      });
    }

    modeling.updateModdleProperties(this.currentElement, formalExpression, {
      body: event.target.value
    });
  }

  elementNeedsSequenceFlowCondition(element) {
    return element.type == 'bpmn:SequenceFlow' &&
      element.source && (
        element.source.type == 'bpmn:ExclusiveGateway' ||
        element.source.type == 'bpmn:InclusiveGateway'
      );
  }

  async loadBPMN(errors) {
    await this.modeler.importXML(this.bpmnTarget.value);
    let canvas = this.modeler.get('canvas');
    canvas.zoom('fit-viewport');
    this.renderErrors(errors);
  }

  saveBPMN() {
    this.modeler.saveXML()
      .then(({ error, xml }) => {
        if (error) {
          alert('Unable to save workflow: ' + error);
        } else {
          this.bpmnTarget.value = xml;
        }
      });
  }
}
