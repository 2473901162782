import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";
import $ from 'jquery';

// Connects to data-controller="calendar"
export default class extends Controller {
  static values = {
    url: String,
    events: String
  };

  connect() {
    this.calendar = new Calendar(this.element, {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      events: this.urlValue,
      eventClick: (event) => this.eventClick(event),
      selectable: true,
      select: (info) => this.selectDates(info)
    });

    this.calendar.render();
  }

  eventClick(event) {
    if (!event.url) {
      return;
    }
    window.location.href = event.url;
  }

  selectDates(info) {
    let start_date = moment(info.start);
    let end_date = moment(info.end);

    $('#non_working_range_start_date').val(start_date.format('YYYY-MM-DD'));
    $('#non_working_range_end_date').val(end_date.subtract(1, 'days').format('YYYY-MM-DD'));
    $('#non_working_range_working').val('');
    $('#non_working_range_status_am').val('');
    $('#non_working_range_status_pm').val('');
    window.$('#non_working_range_repeat_on').val('').trigger('change');
    $('#non_working_range_repeat_until').val('');

    $('#trip_start_date').val(start_date.format('YYYY-MM-DD'));
    $('#trip_end_date').val(end_date.format('YYYY-MM-DD'));

    $('#home_start_date').val(start_date.format('YYYY-MM-DD'));
    $('#home_end_date').val(end_date.format('YYYY-MM-DD'));

    $('#recent_contact_date').val(end_date.format('YYYY-MM-DD'));
    $('#medical_date').val(end_date.format('YYYY-MM-DD'));

    window.$('#non_working_days_modal').modal('show');
  }

  eventsValueChanged() {
    if (this.calendar) {
      this.calendar.refetchEvents()
    }
  }
}
