export const classes = {
  COMPONENT: 'js-tst-weight-field',
  INPUT: 'tst-weight-field__input',
  SWITCHER: 'tst-weight-field__switcher',
  SWITCHER_BUTTON: 'tst-weight-field__switcher-btn',
  STONE_INPUT: 'tst-weight-field__stone',
  LB_INPUT: 'tst-weight-field__lb',
  IMPERIAL: 'tst-weight-field__imperial',
  METRIC: 'tst-weight-field__metric',
  KG_INPUT: 'tst-weight-field__kg'
};

export const attributes = {
  TYPE: 'type'
};
