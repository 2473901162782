export function disableInputs($el) {
  $el.find(':input').each(function() {
    $(this).addClass('field-disabled');
    $(this).prop('disabled', true);
  });

  $el.find('select').each(function() {
    $(this).addClass('field-disabled');
    $(this).prop('disabled', true);
  });

  $el.find('button').each(function() {
    $(this).prop('disabled', true);
  });

  $el.find('.chosen-input').trigger("chosen:updated");
}

export function enableInputs($el) {
  $('.field-disabled').each(function() {
    $(this).removeClass('field-disabled');
    $(this).prop('disabled', false);
  });

  $el.find('button').each(function() {
    $(this).prop('disabled', false);
  });

  $el.find('.chosen-input').trigger("chosen:updated");
}

export function setFormGroupRequired($el, required) {
  var $marker = $el.find('.control-label abbr[title="required"]');
  console.log($marker.length);

  if (required && $marker.length == 0) {
    $el.find('.control-label').prepend('<abbr title="required">*</abbr>');
  } else if (!required && $marker.length > 0) {
    $marker.remove();
  }
}
