function pluralize(value, singular, plural) {
  if (value == 1) {
    return value + " " + singular;
  } else {
    return value + " " + plural;
  }
}

if (window.selected_map_type) {
  $('#users_map').vectorMap({
    map: window.selected_map_type + '_mill',
    backgroundColor: '#fff',
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: '#d1d4db'
      }
    },
    series: {
      regions: [{
        values: window.data,
        scale: ['#C8EEFF', '#0071A4'],
        normalizeFunction: 'polynomial'
      }]
    },
    onRegionTipShow: function(e, el, code) {
      el.html(el.html() + ': ' + pluralize(data[code] || 0, 'user', 'users'));
    },
    onRegionClick: function(e, code) {
      $('#user_report_country').val(code);
      $('#user_report_country').trigger('chosen:updated');
      $('#filters-form').submit();
    }
  });

  $('#map_switch').submit(function(ev) {
    ev.preventDefault();

    $('#map_type').val($('#map_type_switch').val());
    $('#filters-form').submit();
  });
}
