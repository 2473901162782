import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="selectable"
export default class extends Controller {
  static targets = ['selected', 'selectAll'];

  connect() {
    this.selectedElements = [];
    this.selectableElements = Array.from(this.element.querySelectorAll("[data-selectable-id]"));
  }

  toggleSelect(event) {
    if (event.target.checked) {
      this.selectedElements.push(event.target);
    } else {
      this.removeSelectedElement(event.target);
    }

    var allSelected = (this.selectedElements.length == this.selectableElements.length);
    this.selectAllTarget.checked = allSelected;

    this.updateSelectedIds();
  }

  toggleAll(event) {
    this.selectedElements = [];
    this.selectableElements.forEach((element) => {
      element.checked = event.target.checked;
      if (event.target.checked) {
        this.selectedElements.push(element);
      }
    });
    this.updateSelectedIds();
  }

  updateSelectedIds() {
    let targets = this.selectedTargets;
    let ids = this.selectedElements.map((element) => element.getAttribute('data-selectable-id'));
    for (var i = 0; i < targets.length; i += 1) {
      let target = targets[i];
      target.setAttribute('data-selected-ids', ids.join(','));
    }
  }

  removeSelectedElement(element) {
    let idx = this.selectedElements.findIndex((el) => el == event.target);
    if (idx !== -1) {
      this.selectedElements.splice(idx, 1);
    }
  }
}
