import $ from 'jquery';
import { disableInputs, enableInputs } from './form_helpers';

function selectAllApprovals() {
  $('#approval-list').find('input').prop('checked', true);
}

function deselectAllApprovals() {
  $('#approval-list').find('input').prop('checked', false);
}

function bindApprovalForm() {
  $('.edit_approval').on('change', '#approval_status', function() {
    var $form = $(this).closest('form');
    var data = $form.serialize();
    disableInputs($form);

    $.ajax({
      url: $form.attr('action') + '.js',
      type: 'POST',
      data: data,
      complete: function() {
        bindApprovalForm();
      }
    });
  });
}

$(function() {
  bindApprovalForm();

  $('#select-all-approvals').click(selectAllApprovals);
  $('#deselect-all-approvals').click(deselectAllApprovals);
});
