import { setFormGroupRequired } from './form_helpers';

$(function() {
  $('#document_category').change(function() {
    var $form = $(this).closest('form');

    $form.find('.btn').attr('disabled', true);

    $.ajax({
      url: '/documents',
      method: 'POST',
      accept: 'application/json',
      dataType: 'json',
      data: $form.serialize(),
      complete: function(xhr) {
        var response = xhr.responseJSON;
        console.log(response);

        setFormGroupRequired($form.find('.document_country_codes'),
                             response.country_codes_required);
        setFormGroupRequired($form.find('.document_start_date'),
                             response.start_date_required);
        setFormGroupRequired($form.find('.document_end_date'),
                             response.end_date_required);
        $form.find('.btn').removeAttr('disabled');
      }
    });
  });
});
