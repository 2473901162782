import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    active: Boolean,
    url: String,
    delay: { type: Number, default: 1000 }
  };

  connect() {
    if (this.activeValue) {
      this.timeout = setTimeout(() => {
        get(this.urlValue, { responseKind: 'turbo-stream' });
      }, this.delayValue);
    }
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
